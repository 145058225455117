import * as Sentry from '@sentry/browser';
import {
  getDsn,
  getPageLocale,
  getUser,
  getUserType,
} from 'app/common/globals';

Sentry.init({
  dsn: getDsn(),
  release:
    window._config && window._config.sourceVersion
      ? window._config.sourceVersion
      : '',
  whitelistUrls: [/static.indeedassessments.com/],
  blacklistUrls: [/extensions\//i, /^chrome:\/\//i, /^resource:\/\//i],
  // Inspired from https://gist.github.com/impressiver/5092952
  ignoreErrors: [
    //
    // Community maintained ignores
    //
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    //
    // Common errors
    //
    /NS_ERROR_FAILURE/,
    /__gCrWeb.autofill.extractForms/,
    'None [object Event]',
    // Silences bootstrap transition errors
    /.*is transitioning/,
    // Chrome iOS error
    /Can't find variable: inf/,
    // Chrone iOS error https://bugs.chromium.org/p/chromium/issues/detail?id=709132
    /Blocked a frame with origin "https:\/\/[a-zA-z0-9-]+.indeedassessments.com" from accessing a frame with origin "https:\/\/indeedassessments.[a-zA-z0-9-]+.com". Protocols, domains, and ports must match./,
  ],
  sampleRate: 0.1,
});

Sentry.configureScope(scope => {
  scope.setTag('page_locale', getPageLocale());
  scope.setTag('user_type', getUserType());
  scope.setUser(getUser());
});
